// pages/NotFound.js
import React from 'react';

const NotAuthorized = () => {
    return (
        <div className="flex items-center justify-center min-h-screen">
            <div className="text-center">
                <h1 className="text-4xl font-bold mb-4 text-primary">401 - Not Authorized</h1>
                <p className="text-lg">You do not have permission to access this page.</p>
            </div>
        </div>
    );
};

export default NotAuthorized;
